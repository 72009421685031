import './App.css';
import Hero from './components/Hero';
import Services from './components/Services';
import Section1 from './components/Section1';
import Projects from './components/Projects';
import Team from './components/Team';
import Contact from './components/Contact';

function App() {
  return (
    <div className='scroll-smooth'>
      <div className="md:max-w-[90%] md:mx-auto">
        <Hero />
        <Services />
      </div>
      <Section1 />
      <div className="md:max-w-[90%] md:mx-auto">
        <Projects /> 
        <Contact />
        <Team />
      </div>
    </div>
  );
}

export default App;
