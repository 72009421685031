import { Link } from 'react-router-dom'
import logo from '../img/Logo-White.svg'
import instagram from '../img/instagram.svg'
import twitter from '../img/twitter.svg'

const Footer = () => {
    const returnToTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        })
    }
    return (
        <footer className='bg-[#A19387] text-white flex flex-col justify-center items-center pt-8 pb-4'>
            <div className="flex flex-col items-start w-full justify-center gap-8 mb-16 px-4 md:flex-row md:mb-28 md:justify-between md:gap-0 md:items-start lg:lg:max-w-[90%] lg:container lg:mx-auto lg:px-0">
                <div className="flex-1 flex flex-col justify-center gap-3 items-start">
                    <div className="w-44 md:w-52">
                        <img src={logo} alt="Logo" className='max-w-full' />
                    </div>
                    <p className='max-w-[320px] text-start md:w-96'> <b>ARTFRICA PROMOTIONS</b> is a creative agency  with keen focus in BRANDING, PUBLIC RELATIONS & MEDIA.</p>
                </div>
                <div className="flex-1 flex flex-col items-center justify-center text-xl">
                    <div className="flex flex-col justify-center gap-3 items-start">
                        <p className='font-semibold'>Links</p>
                        <Link to="/about" className='cursor-pointer hover:text-[#383337]' onClick={returnToTop}>About Us</Link>
                        <Link to="/" className='cursor-pointer hover:text-[#383337]' onClick={returnToTop}>Services</Link>
                        <Link to="/portfolio" className='cursor-pointer hover:text-[#383337]' onClick={returnToTop}>Portfolio</Link>
                    </div>
                </div>
                <div className="flex-1 flex flex-col items-end justify-center text-xl">
                    <div className="flex flex-col justify-center gap-3 items-start">
                        <p className='font-semibold'>Socials</p>
                        <div className='flex gap-4 items-center justify-around'>
                            <a href="https://instagram.com/artfricahq" target='blank'><img src={instagram} alt="Instagram" className='max-w-full'/></a>
                            <a href="https://twitter.com/artfricahq" target='blank'><img src={twitter} alt="Twitter" className='max-w-full'/></a>
                            <a href="https://twitter.com/artfricahq" target='blank'><img src={twitter} alt="Facebook" className='max-w-full'/></a>
                        </div>
                        <p className='font-semibold'>Email</p>
                        <p className='cursor-pointer hover:text-[#383337]'><a href="mailto:hell@artfrika.com" target="blank">hello@artfrica.com</a></p>
                    </div>
                </div>
            </div>
            <p className='text-base'>2023 All Right Reserved</p>
        </footer>
    )
}

export default Footer
