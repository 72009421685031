import '.././about/Hero.css'


const Hero = () => {
  return (
    <section>
      <div className="portfolio">
                <div className="bg-black/[0.4] w-full min-h-[200px] h-full flex items-end">
                    <div className="px-4 flex justify-start items-end sm:min-h-[470px] lg:px-0 xl:min-h-[670px] lg:container lg:mx-auto">
                        <p className='font-bold text-4xl text-white pb-10 sm:text-5xl md:pb-28'>Our Portfolio</p>
                    </div>
                </div>
            </div>
    </section>
  )
}

export default Hero
