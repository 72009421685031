import arrowMobile from './../../../img/arrowMobile.svg'
import arrow from './../../../img/arrow.svg'

const Section2 = () => {
    return (
        <section className='py-14'>
            <div className='mx-auto px-4 flex justify-start items-start flex-col lg:px-0 lg:container'>
                <div className="flex items-center justify-between w-full mb-6 lg:w-1/2">
                    <h1 className='font-bold text-3xl text-[#383337]'>Here's a backstory</h1>
                    <img src={arrow} alt="" className='cursor-pointer w-20 hidden hover:-ml-4 md:block' />
                    <img src={arrowMobile} alt="" className='cursor-pointer w-2 hover:mt-4 md:hidden' />
                </div>
                <p className='text-[#383337] text-base lg:w-1/2'><b>ARTFRICA PROMOTIONS</b> is a creative agency  with keen focus in BRANDING, PUBLIC RELATIONS & MEDIA. 

Our Versatility is one of our major Strength, being that we have a Quality Team of Creative Writers, Visual Artist, S/M marketers and Media professionals all ready to ensure the Growth of your Business, implementing the Right Skills, Strategy and Technology.
<br></br>
<br></br>
<b>ArtFRICA</b> Promotions provide AFFORDABLE Media Solutions with Effective Creativity and Superb Management, we can design and deliver your message efficiently on the internet space, Television Network, Ourdoor advertising and print media. We also  specialize in drafting Effective Marketing strategy and getting your brand closer to your Customers within the Online and offline space.
<br></br>
<br></br>
<b>OUR GOAL</b> is to help Businesses all across Africa gain the Right Effectual Public presence, stay abreast with GLOBAL MARKET TRENDS, and make increased Profits as a result.

Based on our Understanding of our client brief, we work hand-in-hand with our Clients to achieve their marketing and publicity goal, updating our approach and strategy as we progress..</p>
            </div>
        </section>
    )
}

export default Section2
