import { useState } from 'react'
import logo from '../img/logo-Green.svg'
import { RiMenu4Fill, RiCloseFill } from 'react-icons/ri'
import { NavLink, Link } from 'react-router-dom'
import './Navbar.css'

const returnToTop = () => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth',
  })
}

const Navbar = () => {
  return (
    <header className='mx-auto py-5 px-5 lg:px-0 lg:max-w-[90%] lg:container'>
      {MobileNav()}
      <nav className='justify-between items-center hidden md:flex'>
        <div className="cursor-pointer">
          <Link to="/">
            <img src={logo} alt='Logo' className='w-28' />
          </Link>
        </div>
        <ul className='flex justify-between gap-7 items-start list-none text-sm text-[#A19387]'>
          <NavLink to="/" className="flex items-center flex-col hover:font-bold hover:after:w-12 cursor-pointer after:w-0 after:h-0.5 after:mt-2 after:bg-[#A19387]" onClick={returnToTop}>Why Artfrika</NavLink>
          <NavLink to="/about" className="flex items-center flex-col hover:font-bold hover:after:w-10 cursor-pointer after:w-0 after:h-0.5 after:mt-2 after:bg-[#A19387] " onClick={returnToTop}>About Us</NavLink>
          <NavLink to="/portfolio" className="flex items-center flex-col hover:font-bold hover:after:w-10 cursor-pointer after:w-0 after:h-0.5 after:mt-2 after:bg-[#A19387]" onClick={returnToTop}>Portfolio</NavLink>
        </ul>
      </nav>
    </header>
  )
}

//Mobile Navigation bar

function MobileNav() {

  const [toggle, setToggle] = useState(false)
  function handleToggle() {
    setToggle(prevToggle => !prevToggle)
  }

  const returnToTopMobile = () => {
    setToggle(false)
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  return (
    <nav className='relative z-50 flex flex-col justify-between md:hidden'>
      {
        !toggle && <div className="flex justify-between items-center">
          <div className="cursor-pointer">
            <Link to="/">
              <img src={logo} alt='Logo' className='w-28' />
            </Link>
          </div>
          <div>
            <RiMenu4Fill onClick={handleToggle} className='text-[#A19387] text-4xl cursor-pointer'></RiMenu4Fill>
          </div>
        </div>
      }
      {
        toggle && <ul className='transition flex flex-col items-center justify-start gap-6 text-[#A19387] text-2xl font-semibold fixed bg-white w-full h-screen left-0 top-0 pt-20'>
          <div className='absolute top-5 right-10'>
            <RiCloseFill onClick={handleToggle} className='text-[#A19387] text-4xl cursor-pointer'></RiCloseFill>
          </div>
          <NavLink to="/" className="hover:font-bold hover:text3xl" onClick={returnToTopMobile}>Why Artfrika</NavLink>
          <NavLink to="/about" className="hover:font-bold hover:text3xl" onClick={returnToTopMobile}>About Us</NavLink>
          <NavLink to="/portfolio" className="hover:font-bold hover:text3xl" onClick={returnToTopMobile}>Portfolio</NavLink>
        </ul>
      }
    </nav>
  )
}

export default Navbar
