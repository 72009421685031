import './Hero.css'

const Hero = () => {
    return (
        <section className="">
            <div className="background">
                <div className="bg-black/[0.5] w-full min-h-[200px] max-h-full flex items-end">
                    <div className="px-4 flex justify-start items-end sm:min-h-[470px] lg:px-0 xl:min-h-[670px] lg:container lg:mx-auto">
                        <p className='font-bold text-4xl text-white pb-10 sm:text-5xl md:pb-28'>About Us</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero
