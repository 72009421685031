import arrow from '../img/Swiggle-Arrow.svg'
import hero from '../img/Hero.png'
import { HashLink } from 'react-router-hash-link'
import { motion } from 'framer-motion'
const Hero = () => {
    return (
        <motion.section className='flex items-center justify-center mt-40'
            initial={{
                opacity: 0,
                scale: 0
            }}
            whileInView={{
                opacity: 1,
                scale: 1
            }}
            transition={{
                duration: 1
            }}
            viewport={{
                once: true,
            }}
        >
            <div className="mx-auto px-4 flex flex-col-reverse gap-5 justify-around items-center md:flex-row lg:px-0 md:max-xl:items-start lg:container">
                <div className='flex-auto flex flex-col items-center justify-center md:items-start '>
                    <h1 className='text-black-500 font-bold text-2xl mb-5 max-w-[300px] text-center md:text-start lg:mb-12 lg:text-3xl md:max-w-[600px]'>Do you have a <span className='text-[#A19387]'>Creative Idea?</span> Contact Us lets Build It for You.</h1>
                    <p className='text-[#383337] text-base mb-8 max-w-lg text-center md:text-start'>
                        We design and deliver your Brand's message efficiently, using Social Media, Television Network, Outdoor Advertising and Print media. We also specialize in drafting Effective Marketing strategy and getting your Brand closer to your Customers and Target Audience within the Online and offline space</p>
                    <div className="flex flex-col justify-between items-start gap-4 max-w-max">
                        <HashLink to='#contact' smooth>
                            <p className='bg-[#A19387] hover:bg-[#383337] py-4 px-3 font-bold text-xl text-slate-50 cursor-pointer lg:text-2xl'>
                                Get started Now
                            </p>
                        </HashLink>
                        <img src={arrow} alt="" className='w-24 self-end lg:w-28' />
                    </div>
                </div>
                <div className="flex flex-col max-w-[700px] w-full items-center justify-center bg-[#A19387] shadow-2xl md:max-xl:max-w-[500px] md:max-xl:h-[350px] lg:shadow-3xl">
                    <img src={hero} alt="" className='-mt-10 lg:-mt-20 max-w-full' />
                    <div className="-mt-10 flex-auto bg-[#A19387] blur-md w-full h-20"></div>
                </div>
            </div>
        </motion.section>
    )
}

export default Hero
