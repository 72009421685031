import arrowMobile from './../../../img/arrowMobile.svg'
import arrow from './../../../img/arrow.svg'
import vision from './../../../img/Vision-About-page.jpg'
import './Hero.css'
import { motion } from 'framer-motion'

const Section5 = () => {
    return (
        <motion.section className='mt-16'
            initial={{
                opacity: 0,
                scale: 0
            }}
            whileInView={{
                opacity: 1,
                scale: 1
            }}
            transition={{
                duration: 1
            }}
            viewport={{
                once: true,
            }}
        >
            <div className="px-4 flex flex-col justify-between items-center gap-8 lg:container lg:flex-row lg:mx-auto lg:px-0">
                <div className="hidden w-1/2 lg:block">
                    <img src={vision} alt="Mission" className='h-[700px] max-w-full max-h-full rounded-2xl shadow-5xl' />
                </div>
                <div className='flex justify-start items-start flex-col w-full lg:flex-1 lg:w-1/2'>
                    <div className="flex items-center justify-between w-full mb-6">
                        <img src={arrow} alt="" className='cursor-pointer w-20 hidden hover:-ml-4 lg:block' />
                        <h1 className='font-bold text-3xl text-[#383337]'>Our Vision</h1>
                        <img src={arrowMobile} alt="" className='cursor-pointer w-2 hover:mt-4 lg:hidden' />
                    </div>
                    <p className='text-[#383337] text-base'>Becoming a Leading and Trusted Creative Partner with Brands and Talents by providing them with Standard Up-to-date Media, Branding and PR Solutions, to enable them Compete at Global Level.</p>
                </div>
            </div>
        </motion.section>
    )
}

export default Section5
