import arrowMobile from './../../.././img/arrowMobile.svg'
import arrow from './../../.././img/arrow.svg'

const Section2 = () => {
    return (
        <section className='my-16'>
            <div className="px-4 flex justify-between items-center w-full lg:container lg:px-0 lg:mx-auto">
                <h1 className='text-[#383337] text-4xl font-bold sm:text-5xl'>Here's What We've Done</h1>
                <img src={arrow} alt="" className='cursor-pointer w-20 hidden hover:-ml-4 md:block' />
                <img src={arrowMobile} alt="" className='cursor-pointer w-2 hover:mt-4 md:hidden' />
            </div>
        </section>
    )
}

export default Section2
