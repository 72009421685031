import work1 from './../../../img/work1.png'
import work2 from './../../../img/work2.png'
import arrow from './../../.././img/arrow.svg'
import work3 from './../../../img/work3.png'
import '../../projects.css';
import { motion } from 'framer-motion'


const Section3 = () => {

    const projects = [
        {
            img: work1,
            title: 'Project Title',
            category: 'Category',
            date: '11th December 2021',
            alt: 'First Project'
        },
        {
            img: work2,
            title: 'Project Title',
            category: 'Category',
            date: '11th December 2021',
            alt: 'Second Project'
        },
        {
            img: work3,
            title: 'Project Title',
            category: 'Category',
            date: '11th December 2021',
            alt: 'Third Project'
        }
    ]

    return (
        <motion.section className='py-12 relative pl-4 md:pl-0'
            initial={{
                opacity: 0,
                scale: 0
            }}
            whileInView={{
                opacity: 1,
                scale: 1
            }}
            transition={{
                duration: 1
            }}
            viewport={{
                once: true,
            }}
        >
            <div className='px-4 mx-auto relative lg:px-0 lg:container'>
                <div className="flex justify-between items-center mb-10 md:mb-16">
                    <h1 className='font-bold text-3xl'>Online Public Relations</h1>
                    <img src={arrow} className='cursor-pointer w-10 hover:-ml-4 md:w-20' />
                </div>
                <div className='overflow'>
                    {
                        projects.map((project) => (
                            <div className='top-0 flex flex-col items-start justify-center gap-3 min-w-[297px] md:min-w-[370px] lg:min-w-[640px]'>
                                <img src={project.img} alt={project.alt} className='border cursor-pointer max-w' />
                                <div className="flex items-center justify-center gap-3">
                                    <p className='cursor-pointer font-bold text-xl lg:text-3xl'>{project.title}</p>
                                    <span className='py-3 px-0.5 bg-black'></span>
                                    <p className='cursor-pointer font-bold text-xl lg:text-3xl'>{project.category}</p>
                                </div>
                                <p>Published: {project.date}</p>
                            </div>
                        ))
                    }
                </div>
            </div >
        </motion.section >
    )
}

export default Section3
