import arrow from '../img/arrow.svg'
import {motion} from 'framer-motion'
import arrowMobile from '../img/arrowMobile.svg'

const Services = () => {
    return (
        <motion.section className='py-10 lg:py-28'
            initial={{
                opacity: 0,
                scale: 0
            }}
            whileInView={{
                opacity: 1,
                scale: 1
            }}
            transition={{
                duration: 1
            }}
            viewport={{
                once: true,
            }}
        >
            <div className="px-4 mx-auto gap-5 flex flex-col items-center justify-between lg:flex-row lg:px-0 lg:container">
                <div className='flex flex-col justify-start items-start lg:items-center'>
                    <div className="flex gap-10 items-center justify-between mb-7 w-full">
                        <h1 className='text-[#383337] text-3xl font-bold'>Our Services</h1>
                        <img src={arrow} alt="" className='cursor-pointer w-20 hidden hover:-ml-4 lg:block' />
                        <img src={arrowMobile} alt="" className='cursor-pointer w-2 hover:mt-4 lg:hidden' />
                    </div>
                    <div className='flex flex-col items-center justify-center gap-6 w-full'>
                        <p className='text-base text-[#383337] w-full lg:max-w-sm'>At <b>ARTFRICA</b>, we are passionate about what we do, and we take pride in delivering exceptional service and expertise in each of our individual offerings. Contact us today and discover how our specialized services can elevate your brand, boost your online presence, and drive meaningful engagement.</p>
                    </div>
                </div>
                <div className="flex items-end justify-center">
                    <div className="flex flex-col items-center justify-center gap-4 flex-auto">
                        <div className="flex items-center flex-col justify-center gap-4 flex-auto sm:flex-row">
                            <div className='flex flex-col items-center justify-center gap-4'>
                                <div className="flex flex-auto flex-col rounded-xl py-6 px-4 max-w-[256px] justify-center gap-3 bg-[#A19387]">
                                    <p className='text-base font-bold text-slate-50 max-w-xs'>Online Public Relations</p>
                                    <p className='text-white text-xs min-w-[200px]'>Our Online Public Relations service is designed to help you establish and maintain a strong reputation in the digital realm. </p>
                                </div>
                                <div className="flex flex-auto flex-col rounded-xl py-6 px-4 max-w-[256px] justify-center gap-3 bg-[#A19387]">
                                    <p className='text-base font-bold text-slate-50 max-w-xs'>Content Creation</p>
                                    <p className='text-white text-xs min-w-[200px]'>Content is king, and our team of skilled writers, designers, and videographers are here to reignite your brand's creative spark. </p>
                                </div>
                            </div>
                            <div className='flex flex-col items-center justify-center gap-4'>
                                <div className="flex flex-col flex-auto rounded-xl py-6 px-4 max-w-[256px] justify-center gap-3 bg-[#A19387]">
                                    <p className='text-base font-bold text-slate-50 max-w-xs'>TV PR and News PR</p>
                                    <p className='text-white text-xs min-w-[200px]'>In the fast-paced world of television and news media, capturing attention is paramount. Our TV PR and News PR services excel at securing media coverage that showcases your brand's unique value proposition. </p>
                                </div>
                                <div className="flex flex-col flex-auto rounded-xl py-6 px-4 max-w-[256px] justify-center gap-3 bg-[#A19387]">
                                    <p className='text-base font-bold text-slate-50 max-w-xs'>Digital Marketing</p>
                                    <p className='text-white text-xs min-w-[200px]'>In the ever-evolving digital landscape, effective marketing strategies are crucial to stay ahead of the competition.</p>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col flex-auto rounded-xl py-6 px-4 max-w-[256px] justify-center gap-3 bg-[#A19387]">
                            <p className='text-base font-bold text-slate-50 max-w-xs'>Event Coverage & Live-Streaming</p>
                            <p className='text-white text-xs min-w-[200px]'>Creating memorable experiences is at the heart of any successful event, and our Event Coverage & Live-Streaming services ensure that your events reach a wider audience.</p>
                        </div>
                    </div>
                </div>
            </div>
        </motion.section>
    )
}

export default Services
