import mab from '../img/team1.jpg'
import mab2 from '../img/Mission-About-page.jpg'
import arrow from '../img/arrow.svg'
import { motion } from 'framer-motion'

const Projects = () => {

    const team = [
        {
            img: mab,
            name: 'Emmanuel Kalasuwe',
            title: 'Founder & Creative Director',
            alt: 'Emmanuel Kalasuwe'
        },
        {
            img: mab2,
            name: 'Babalola Allengege',
            title: 'Lead Developer',
            alt: 'Babalola Allengege'
        }
    ]

    return (
        <motion.section className='py-5 md:py-12'
            initial={{
                opacity: 0,
                scale: 0
            }}
            whileInView={{
                opacity: 1,
                scale: 1
            }}
            transition={{
                duration: 1
            }}
            viewport={{
                once: true,
            }}
        >
            <div className='px-4 mx-auto lg:container lg:px-0'>
                <div className="flex justify-between items-center mb-20">
                    <h1 className='font-bold text-3xl md:text-6xl'>Meet The Team Members</h1>
                    <img src={arrow} className='cursor-pointer w-28 hidden hover:-ml-4 lg:block' />
                </div>
                <div className='flex flex-wrap items-start justify-between gap-6 md:flex-nowrap'>
                    {
                        team.map((member) => (
                            <div className='flex flex-col items-center justify-around gap-3 border'>
                                <img src={member.img} alt={member.alt} className='cursor-pointer max-w-full' />
                                <p className='cursor-pointer font-semibold text-xl'>{member.name}</p>
                                <p className='cursor-pointer text-base'>{member.title}</p>
                            </div>
                        ))
                    }
                </div>
            </div>
        </motion.section >
    )
}

export default Projects
