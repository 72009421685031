import { motion } from 'framer-motion'

const Contact = () => {
    return (
        <motion.section id="contact" className='my-16 flex items-center justify-between'
            initial={{
                opacity: 0,
                scale: 0
            }}
            whileInView={{
                opacity: 1,
                scale: 1
            }}
            transition={{
                duration: 1
            }}
            viewport={{
                once: true,
            }}
        >
            <div className="mx-auto px-4 flex flex-col gap-4 items-start w-full justify-between lg:flex-row lg:gap-0 lg:px-0 lg:container lg:items-center">
                <h1 className='font-bold text-5xl text-[#383337] max-w-[458px] lg:text-7xl'>Have something in mind?</h1>
                <form action="" className='flex items-center justify-center flex-col w-full lg:w-auto'>
                    <div className="flex items-center justify-center gap-3 w-full">
                        <input type="text" placeholder='Name' className=' rounded-md w-1/2 border outline-none border-[#707070] placeholder:text-[#383337] placeholder:text-sm font-semibold p-3 lg:placeholder:text-xl' />
                        <input type="text" placeholder='Company Name' className=' rounded-md w-1/2 border outline-none border-[#707070] placeholder:text-[#383337] placeholder:text-sm font-semibold p-3 lg:placeholder:text-xl' />
                    </div>
                    <input type="text" placeholder='Email' className='my-3 rounded-md w-full border outline-none border-[#707070] placeholder:text-[#383337] placeholder:text-sm font-semibold p-3 lg:placeholder:text-xl' />
                    <textarea type="text" placeholder='Details' className=' rounded-md w-full min-h-[190px] border outline-none border-[#707070] placeholder:text-[#383337] placeholder:text-md font-semibold px-3 pt-3 pb-3 lg:placeholder:text-xl' />
                    <button className='bg-[#A19387] py-4 px-3 font-bold text-sm mt-3 w-full self-right text-slate-50 cursor-pointer self-end md:text-xl lg:w-1/3 md:py-3 lg:py-4 hover:bg-[#383337]'>Submit</button>
                </form>
            </div>
        </motion.section>
    )
}

export default Contact
