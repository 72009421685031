import wab1 from '../img/wab1.png'
import wab2 from '../img/wab2.png'

const Section1 = () => {
    return (
        <section className="pb-10">
            <div className="flex justify-center relative items-center w-full md:justify-between">
                <img src={wab1} alt="We are best" className='max-w-full w-3/4 md:w-1/2 lg:w-1/3' />
                    <img src={wab2} alt="We are best" className='max-w-full md:w-1/2 lg:w-1/3 hidden md:block' />
                {/* Overlay */}
                <div className="bg-black/[0.6] absolute w-full h-full flex items-center">
                    <div className="mx-auto px-4 lg:px-0 lg:max-w-[90%] lg:container">
                        <p className='font-bold text-3xl text-slate-50 max-w-[300px] sm:max-w-[400px] md:max-w-[430px] sm:text-5xl'>We are Best at what we Do!!</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Section1
