import arrowMobile from './../../.././img/arrowMobile.svg'
import arrow from './../../.././img/arrow.svg'

const Section1 = () => {
    return (
        <section className='py-14'>
            <div className='mx-auto px-4 flex justify-start items-start flex-col lg:px-0 lg:container'>
                <div className="flex items-center justify-between w-full mb-6 lg:w-1/2">
                    <h1 className='font-bold text-3xl text-[#383337]'>We are Best at what We Do</h1>
                    <img src={arrow} alt="" className='cursor-pointer w-20 hidden hover:-ml-4 md:block' />
                    <img src={arrowMobile} alt="" className='cursor-pointer w-2 hover:mt-4 md:hidden' />
                </div>
                <p className='text-[#383337] text-base lg:w-1/2'>Apparently we had reached a great height in the atmosphere, for the sky was a dead black, and the stars had ceased to twinkle. By the same illusion which lifts the horizon of the sea to the level of the spectator on a hillside, the sable cloud beneath was dished out, and the car seemed to float in the middle of an immense dark sphere, whose upper half was strewn with silver. Looking down into the dark gulf below, I could see a ruddy light streaming through a rift in the clouds.</p>
            </div>
        </section>
    )
}

export default Section1
