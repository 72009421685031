// import reportWebVitals from './reportWebVitals';
// reportWebVitals();

import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
  Outlet,
} from "react-router-dom";
import './index.css';
import App from "./App";
import About from './components/routes/about/About';
import Portfolio from './components/routes/portfolio/Portfolio';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Contact from "./components/Contact";


const Layout = () => (
  <>
    <Navbar />
    <Outlet />
    <Footer />
  </>
)

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <App />
      },
      {
        path: "about",
        element: <About />
      },
      {
        path: "portfolio",
        element: <Portfolio />
      },
      {
        path: "contact",
        element: <Contact />
      },
    ],
  },
]);

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);