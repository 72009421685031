import './Hero.css'

const Section3 = () => {
    return (
        <section className="">
            <div className="quote">
                <div className="bg-black/[0.5] w-full min-h-[200px] h-[full] flex items-center">
                    <div className="px-4 flex justify-start items-center md:min-h-[470px] xl:min-h-[670px] lg:px-0 lg:container lg:mx-auto">
                        <p className='font-bold text-3xl text-white max-w-[350px] md:max-w-[550px] md:text-5xl'>“To have a great idea, have a lot of them.”<span className='mt-3 block font-semibold text-lg md:text-xl md:mt-3'>– Thomas A. Edison</span></p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Section3
